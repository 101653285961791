export default {
  primary: {
    color: 'background',
    bg: 'green'
  },
  muted: {
    color: 'text',
    bg: 'muted'
  }
}
