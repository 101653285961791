import React from 'react'
import { Container, Box, Flex } from 'theme-ui'
import { FooterLogo } from './Footer.Logo'
import { FooterMenu } from './Footer.Menu'
import { FooterSocial } from './Footer.Social'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`,
    mt: [5, 6]
  }
}

export const Footer = ({ location }) => {
  function hasApp() {
    if (location.pathname.indexOf('app') > -1) {
      return true
    }
    return false
  }

  let displayFooter = hasApp()

  return (
    <Box sx={styles.wrapper}>
      {!displayFooter ? (
        <Container variant='compact' sx={styles.container}>
          <Flex variant='layout.footer'>
            <Box>
              <FooterLogo />
            </Box>
            <FooterMenu />
          </Flex>
        </Container>
      ) : null}
    </Box>
  )
}
