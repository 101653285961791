import common from './common'

export default {
  ...common.badge,
  bg: `green`,
  color: `white`,
  ':hover': {
    color: `omegaLight`,
    bg: `omegaDark`
  }
}
