import common from './common'

export default {
  ...common.button,
  color: `omegaDark`,
  bg: `none`,
  borderColor: `mute`,
  ':hover': {
    textDecoration: 'underline'
  },
  marginTop: '0.6em'
}
