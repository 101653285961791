import { Link } from 'gatsby'
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import useHover from './use-hover'

const Dropdown = ({ items, name, slug, rel }) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <MenuContainer ref={hoverRef}>
      <button
        className={`menu-trigger ${name === 'Verify' ? 'VerifyBtn' : ''}`}
      >
        <StyledLink to={`${slug}`}>{name}</StyledLink>
      </button>
      <nav className={`menu ${isHovered ? 'active' : 'inactive'}`}>
        <ul>
          {items
            ? items.map(menuItem => {
                return (
                  <li>
                    <a href={`${menuItem.slug}`}>{menuItem.name}</a>
                  </li>
                )
              })
            : null}
        </ul>
      </nav>
    </MenuContainer>
  )
}

const MenuContainer = styled.div`
  position: relative;

  .menu {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 60px;
    right: 0;
    width: 300px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 1s ease, transform 0.4s ease, visibility 0.4s;
  }

  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu li {
    border-bottom: 1px solid #dddddd;
  }

  .menu li a {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
  }

  .menu-trigger {
    background: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
  }
  .VerifyBtn {
    background: #667eea !important;
  }
  .VerifyBtn a {
    color: #fff !important;
  }
  .menu-trigger:hover {
    box-shadow: 0 0.4px 8px rgba(0, 0, 0, 0.3);
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
  font-weight: 700;
`
export default Dropdown
